import { httpClient } from './httpClient.js';

const REFUND_PATH = '/dashboard/refund';
const REFUND_PATH_DATA = '/dashboard/refund/';
const REFUND_APPROVE_PATH = '/approve';
const REFUND_REJECT_PATH = '/reject';
const GET_REFUNDABLE_AMOUNT_PATH = '/dashboard/refund/refundable/'
const RESEND_EMAIL_LINK_TO_CUSTOMER = '/dashboard/refund/resend-refund-link/'

export const createRefund = (payload) => httpClient.dashboardService.post(REFUND_PATH, payload);
export const getRefundList = (params) => httpClient.dashboardService.get(REFUND_PATH, { "params": params });
export const getRefundDetail = (id) => httpClient.dashboardService.get(`${REFUND_PATH}/${id}`);
export const approveRefund = (id) => httpClient.dashboardService.post(REFUND_PATH_DATA + id + REFUND_APPROVE_PATH);
export const rejectRefund = (id, data) => httpClient.dashboardService.post(REFUND_PATH_DATA + id + REFUND_REJECT_PATH, data);
export const getRefundableAmount = (payment_id) => httpClient.dashboardService.get(`${GET_REFUNDABLE_AMOUNT_PATH}${payment_id}`);
export const resendEmailLinkToCustomer = (refund_id) => httpClient.dashboardService.get(`${RESEND_EMAIL_LINK_TO_CUSTOMER}${refund_id}`);