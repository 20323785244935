<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol md="6">
          <wallet class="float-left margin-right-top" />
          <div class="float-left">
            <small class="text-muted d-block">
              {{ $t("DISBURSEMENTS.LABELS.YOUR_BALANCE") }}
            </small>
            <strong class="balance"> Rp {{ formatAmount(balance) }} </strong>
          </div>
          <CButton
            @click="goTo('/disbursements/topup')"
            color="durianprimary"
            size="lg"
            class="float-left ml-3"
            v-show="hasButtonTopup"
            v-element-access="permissions.topupCreate"
          >
            {{ $t("DISBURSEMENTS.LABELS.TOPUP") }}
          </CButton>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import { getBalance } from "@/api/disbursement.api.js";
import Wallet from "./Wallet.vue";

export default {
  components: { Wallet },
  name: "BalanceCard",
  props: ["hasButtonTopup"],
  data() {
    return {
      balance: "0",
    };
  },
  methods: {
    getUserBalance() {
      getBalance()
        .then((response) => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.balance = response.data.data.balance.toString();
          } else {
            this.showToaster(
              this.$t(this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_BALANCE"))
            );
          }
        })
        .catch(this.handleAPIError);
    },
  },
  mounted() {
    this.getUserBalance();
  },
};
</script>
<style>
.balance {
  line-height: 1;
  font-size: 16px;
}
.margin-right-top {
  margin-right: 10.67px;
  margin-top: 5px;
}
</style>
