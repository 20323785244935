<template>
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 20V21.3333C24 22.8 22.8 24 21.3333 24H2.66667C1.18667 24 0 22.8 0 21.3333V2.66667C0 1.2 1.18667 0 2.66667 0H21.3333C22.8 0 24 1.2 24 2.66667V4H12C10.52 4 9.33333 5.2 9.33333 6.66667V17.3333C9.33333 18.8 10.52 20 12 20H24ZM12 17.3333H25.3333V6.66667H12V17.3333ZM17.3333 14C16.2267 14 15.3333 13.1067 15.3333 12C15.3333 10.8933 16.2267 10 17.3333 10C18.44 10 19.3333 10.8933 19.3333 12C19.3333 13.1067 18.44 14 17.3333 14Z"
      fill="#818386"
    />
  </svg>
</template>

<script>
export default {
  name: "Wallet",
};
</script>