<template>
  <div>
    <CRow>
      <CCol col="12" xl="12">
        <balance-card :hasButtonTopup="true" :balance="0" />
      </CCol>
    </CRow>
    <CCard>
      <CCardBody>
        <CRow align-vertical="center">
          <CCol col="4">
            <h2 class="float-left h5 mb-0">{{ $t("REFUND.REFUND_LIST") }}</h2>
          </CCol>
          
          <CCol col="8">            
            <CButton
              class="px-2 float-right"
              @click="goTo('refunds/new')"
              color="durianprimary"
              v-element-access="permissions.refundCreate"
            >
              <CIcon name="cil-library-add" />
              {{ $t("REFUND.CREATE_NEW") }}
            </CButton>

            <CInput
                  :placeholder="$t('REFUND.SEARCH')"
                  v-model="filters.search"
                  @input="fetchData"
                  class="px-2 float-right col-sm-3"
                />

            <CButton
              class="px-2 float-right mr-one-percent col-sm-3"
              @click="filterModalDate = true"
              size="sm"
              color="lightborder"
              style="width : 115px"
            >
              <CIcon name="cil-filter" />&nbsp;{{ $t("COMMON.FILTERS") }}
            </CButton>

          </CCol>          
        </CRow>
        <CRow class="mt-2 mb-4">
          <CCol col="12" class="pills-wrapper tab-border py-1">
            <b-nav pills>
              <b-nav-item
                v-for="status in refundStatus"
                @click="selectStatus(status)" :active="active === status"
                v-bind:key="status"
              >
                {{ $t(`REFUND.STATUS.${status.toUpperCase()}`) }}
              </b-nav-item>
            </b-nav>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12">
          <CDataTable
              hover
              :items="refundList"
              :fields="fields"
              @row-clicked="(item, index) => goTo('refunds/', item['id'])"
            >
              <template #id="data">
                <td class="td-center td-refund-id">{{ data.item.id }}</td>
              </template>
              <template #amount="data">
                <td class="text-right td-center">Rp {{ formatAmount(data.item.total_amount.toString()) }}</td>
              </template>
              <template #refund_partial="data">
                <td class="capitalize td-center">{{ data.item.refund_partial || "-" }}</td>
              </template>
              <template #refund_type="data">
                <td class="capitalize td-center">{{ data.item.type }}</td>
              </template>
              <template #payment_id="data">
                <td class="td-center">{{ data.item.payment_id }}</td>
              </template>
              <template #customer="data">
                <td class="td-center">{{ (data.item.customer_email != "" ? data.item.customer_email : data.item.customer_phone) || data.item.customer_id }}</td>
              </template>
              <template #created="data">
                <td>
                  <div>{{ data.item.created_by_name || "-" }}</div>
                  <small>{{ formatDate(data.item.created_at) }}</small>
                </td>
              </template>
              <template #updated="data">
                <td>
                  <div>{{ data.item.updated_by_name || "-" }}</div>
                  <small>{{ formatDate(data.item.updated_at) }}</small>
                </td>
              </template>
              <template #status="data">
                <td class="td-center">
                  <CBadge :color="getRefundBadge(data.item.status)">
                    {{ $t(`REFUND.STATUS.${data.item.status.toUpperCase()}`) }}
                  </CBadge>
                </td>
              </template>
            </CDataTable>

            <div class="col-md-11">
              <b-pagination
                size="md"
                align="center"
                :total-rows="totalRows"
                @change="pageChange"
                v-model="pageNumber"
                :per-page="filters.limit"
                :items="refundList"
                :current-page="pageNumber"
              ></b-pagination>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CModal
      :title="$t('COMMON.FILTERS_HEADER')"
      color="light"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="filterModal"
    >
      <CContainer>        
        <CRow class="mb-3">
          <label class="col-12 col-xl-12">{{
            $t("REFUND.TYPE")
          }}</label>
          <multiselect
            v-model="value2"
            class="mx-3"
            :options="options2"
            :multiple="false"
            :placeholder="$t('REFUND.TYPE')"
            track-by="name"
            label="name"
            @select="selectFilter2"
            @remove="clearFilter2"
          ></multiselect>
        </CRow>
      </CContainer>
      <template #footer>
        <CButton @click="resetFilter">{{
          $t("COMMON.RESET")
        }}</CButton>
        <CButton @click="filterModal = false" color="durianprimary">{{
          $t("COMMON.DONE")
        }}</CButton>
      </template>
    </CModal>

    <CModal
      :title="$t('COMMON.FILTERS_HEADER')"
      color="light"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="filterModalDate"
    >
      <CContainer>        
        <CRow class="mb-3">
          <label class="row col-12 col-xl-12">{{
          $t("ORDERS.FILTER_BY.DATE")
        }}</label>
          <date-picker
            class="primary col-12 col-xl-12"
            v-model="dates"
            type="date"
            range
            placeholder="Select date range"
            @change="selectRange"
          ></date-picker>
        </CRow>
      </CContainer>
      <template #footer>
        <CButton @click="resetFilter">{{
          $t("COMMON.RESET")
        }}</CButton>
        <CButton @click="filterModalDate = false" color="durianprimary">{{
          $t("COMMON.DONE")
        }}</CButton>
      </template>
    </CModal>

  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { getRefundList } from "@/api/refunds.api.js";
import BalanceCard from '../disbursements/components/BalanceCard.vue';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
const DefaultStatus = "all" , TypeNormal = 'normal', TypeInstant = 'instant', formatDate = 'YYYY-MM-DD';

export default {
  components: { Multiselect, BalanceCard, DatePicker },
  name: "Refund",
  data() {
    return {
      active: DefaultStatus,
      filterModal: false,
      filterModalDate: false,
      totalRows: 1000,
      pageNumber: 1,
      refundStatus: [
        "all",
        "started",
        "rejected",
        "approved",        
        "disbursement_processing",
        "disbursement_failed",
        "done",
      ],
      fields: [
        { key: "id", label: this.$t("REFUND.LABEL.ID"), _classes: "font-weight-bold" },
        { key: "amount", label: this.$t("REFUND.LABEL.REFUND_AMOUNT"), _classes: "ta-right" },
        { key: "refund_partial", label: this.$t("REFUND.LABEL.CATEGORY") },
        { key: "payment_id", label: this.$t("REFUND.LABEL.PAYMENT_ID") },
        { key: "customer", label: this.$t("REFUND.LABEL.CUSTOMER") },
        { key: "created", label: this.$t("REFUND.LABEL.CREATED") },
        { key: "updated", label: this.$t("REFUND.LABEL.UPDATED") },
        { key: "status", label: this.$t("REFUND.LABEL.STATUS") },
      ],
      filters: {
        skip: 0,
        limit: 50,
        filters: [],
        from: "",
        to: "",
        search: "",
      },
      refundList: [],
      options2: [
        { name: "normal", column: "type" },
        { name: "instant", column: "type" },
      ],
      value1: null,
      value2: null,
      value3: null,
      dates: [],
      today: "",
      before: "",
    };
  },
  methods: {
    selectRange() {
      if (this.ifValid(this.dates[0]) && this.ifValid(this.dates[1])) {
        this.filters.from = this.moment(this.dates[0])
          .format(formatDate)
          .toString();
        this.filters.to = this.moment(this.dates[1])
          .format(formatDate)
          .toString();
      } else {
        this.filters.from = this.before;
        this.filters.to = this.today;
      }      
      this.getPaymentsByDate();
    },
    getPaymentsByDate() {
      if (this.filters.from && this.filters.to) {
        this.fetchData();
      } else if (
        this.filters.from == "" &&
        this.filters.to == ""
      ) {
        this.fetchData();
      }
    },
    selectFilter2(val) {
      this.removeFromFilter(val.column);
      let f = {};
      if (val.name == TypeInstant) {
        f.value = TypeInstant;
      } else {
        f.value = TypeNormal;
      }
      f.key = val.column;
      this.filters.filters.push(f);
      this.filters.skip = 0;
      this.fetchData();
    },
    clearFilter2(val) {
      this.value2 = null;
      let removeElementAtIndex = -1;
      this.filters.filters.forEach(function (v, index) {
        if (v.key === val.column) {
          removeElementAtIndex = index;
        }
      });
      if (removeElementAtIndex !== -1) {
        this.filters.filters.splice(removeElementAtIndex, 1);
      }
      this.fetchData();
    },
    selectStatus(status) {
      this.active = status;
      let filter = {};
      filter.column = "status";
      filter.name = status;
      if (status != DefaultStatus) {
        this.selectFilter1(filter);
      } else {
        this.clearFilter1(filter);
      }
    },
    selectFilter1(val) {
      this.removeFromFilter(val.column);
      let f = {};
      f.value = val.name;
      f.key = val.column;
      this.filters.filters.push(f);
      this.filters.skip = 0;
      this.fetchData();
    },
    removeFromFilter(val) {
      let removeIndex = -1;
      this.filters.filters.find((v, i) => {
        if (v.key === val) {
          removeIndex = i;
        }
      });
      if (removeIndex != -1) {
        this.filters.filters.splice(removeIndex, 1);
      }
    },
    clearFilter1(val) {
      this.value1 = null;
      let removeElementAtIndex = -1;
      this.filters.filters.forEach(function (v, index) {
        if (v.key === val.column) {
          removeElementAtIndex = index;
        }
      });
      if (removeElementAtIndex !== -1) {
        this.filters.filters.splice(removeElementAtIndex, 1);
      }
      this.fetchData();
    },
    pageChange(val) {
      this.filters.skip = (val - 1) * this.filters.limit;
      this.fetchData();
    },
    fetchData() {
       const {
        skip,
        limit,
        from,
        to,
        search,
        filters,
      } = this.filters;
      let paramsMap = new Map();
      paramsMap["skip"] = skip;
      paramsMap["limit"] = limit;
      paramsMap["from"] = from || this.before;
      paramsMap["to"] = to || this.today;
      paramsMap["search"] = search;
      paramsMap["filters"] = JSON.stringify(filters);

      getRefundList(this.generatePostParams(paramsMap))
        .then((response) => {
          const { data } = response.data;
          this.totalRows = data.total_data;
          this.refundList = data.refund;
        })
        .catch((e) => {
          if (e) this.showToaster(e);
        });
    },
    resetFilter() {
      this.dates = [];
      this.value2 = null;
      this.removeFromRefundFilter("type");
      this.initializeData();
    },
    removeFromRefundFilter(val) {
      let removeIndex = -1;
      this.filters.filters.find((v, i) => {
        if (v.key === val) {
          removeIndex = i;
        }
      });
      if (removeIndex != -1) {
        this.filters.filters.splice(removeIndex, 1);
      }
    },
    async initializeData() {
      const dates = await this.initializeDates();
      this.today = dates.today;
      this.before = dates.before;
      this.filters.to = this.today;
      this.filters.from = this.before;
      await this.fetchData();
    },
  },
  mounted() {
    this.initializeData();
  },
};
</script>

<style scoped>
.tab-border {
  border-top: 1px solid rgba(0, 0, 21, 0.2);
  border-bottom: 1px solid rgba(0, 0, 21, 0.2);
}
.td-center {
  vertical-align: middle !important;
}
.td-refund-id {
  color: #e86163;
  cursor: pointer;
  font-weight: bold;
}
.td-refund-id:hover {
  text-decoration: underline;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>