import { httpClient } from './httpClient.js';

const DISBURSEMENT_ROUTES = '/dashboard/disbursements/';
const UPDATE_DISBURSEMENT_ITEMS = '/dashboard/disbursements/items/update';
const GET_BANK_LIST = '/dashboard/disbursements/banks';
const GET_TOPUP_LIST = '/dashboard/topup';
const GET_BALANCE = '/dashboard/topup/balance';
const GET_USERS = '/dashboard/disbursements/users';
const DELETE_DISBURSEMENT_ITEMS = '/dashboard/disbursements/items/delete';
const VALIDATE_DISBURSEMENT_ITEMS = 'dashboard/disbursements/inquiries';
const GET_DISBURSEMENT_TOPUP_HISTORY = '/dashboard/topup';
const GET_TOPUP_BANKS = '/dashboard/disbursements/banks';
const UPLOAD_DISBURSEMENT_FILE = '/dashboard/disbursements/upload';
const SUBMIT_DISBURSEMENT_FILE = '/dashboard/disbursements/submit';
const GET_TOPUP_INSTRUCTIONS = 'dashboard/topup';
const GET_TRANSFER_FEES = '/dashboard/disbursements/transfer_fee';

const getBankList = async () => await httpClient.dashboardService.get(GET_BANK_LIST);
const getTopUpList = async () => await httpClient.dashboardService.get(GET_TOPUP_LIST);
const getBalance = async () => await httpClient.dashboardService.get(GET_BALANCE);
const getTransferFees = async () => await httpClient.dashboardService.get(GET_TRANSFER_FEES);
const getDisbursementApprovers = async () => {
    const params = { role: "Approver" };
    return await httpClient.dashboardService.get(GET_USERS, { "params": params });
};

const getDisbursementItemsByID = async (id, params) => {
    return await httpClient.dashboardService.get(DISBURSEMENT_ROUTES + id + '/items', { "params": params });
}
const updateDisbursementItems = async (payload) => {
    return await httpClient.dashboardService.post(UPDATE_DISBURSEMENT_ITEMS, payload);
}
const deleteDisbursementItems = async (payload) => {
    return await httpClient.dashboardService.post(DELETE_DISBURSEMENT_ITEMS, payload);
}
const validateDisbursementItems = async (payload) => {
    return await httpClient.dashboardService.post(VALIDATE_DISBURSEMENT_ITEMS, payload);
}

const getTopUpHistory = async (params) => {
    return await httpClient.dashboardService.get(GET_DISBURSEMENT_TOPUP_HISTORY, { "params": params });
}
const getTopUpBanks = async (params) => {
    return await httpClient.dashboardService.get(GET_TOPUP_BANKS, { "params": params });
}
const submitDisbursement = async (id, payload) => {
    return await httpClient.dashboardService.post(DISBURSEMENT_ROUTES + id + '/confirm', payload);
}
const uploadDisbursement = async (formdata) => {
    let config = {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }
    return await httpClient.dashboardService.post(UPLOAD_DISBURSEMENT_FILE, formdata, config);
}

const submitDisbursementFile = async (payload) => {
    return await httpClient.dashboardService.post(SUBMIT_DISBURSEMENT_FILE, payload);
}
const getCountAndAmount = async (id) => {
    return await httpClient.dashboardService.get(DISBURSEMENT_ROUTES + id + '/count');
}
const getTopupInstructions = async (payload) => {
    return await httpClient.dashboardService.post(GET_TOPUP_INSTRUCTIONS, payload);
}

const listDisbursements = async (listParams) => {
    return await httpClient.dashboardService.get(DISBURSEMENT_ROUTES, { "params": listParams });
}

const getDisbursementMakers = async () => {
    const params = { role: "Maker" };
    return await httpClient.dashboardService.get(GET_USERS, { "params": params });
}

const getDisbursementAdmins = async () => {
    const params = { role: "Admin" };
    return await httpClient.dashboardService.get(GET_USERS, { "params": params });
}

const getDisbursementByID = async (id) => {
    return await httpClient.dashboardService.get(DISBURSEMENT_ROUTES + id);
}

const exportDisbursementBatchItems = async (id, params) => {
    return await httpClient.dashboardService.get(DISBURSEMENT_ROUTES + id + "/items", { "params": params , responseType: 'blob' });
}
const rejectDisbursmentByID = async (id, payload) => {
    return await httpClient.dashboardService.post(DISBURSEMENT_ROUTES + id + '/reject', payload);
}
const approveDisbursementByID = async (id, payload) => {
    return await httpClient.dashboardService.post(DISBURSEMENT_ROUTES + id + '/approve', payload);
}
const resubmitDisbursementByID = async (id, payload) => {
    return await httpClient.dashboardService.post(DISBURSEMENT_ROUTES + id + '/resubmit', payload);
}

const getTopUpInstructionsByID = async (id) => {
    return await httpClient.dashboardService.get(GET_TOPUP_INSTRUCTIONS + `/${id}`);
}
export {
    getBankList,
    getBalance,
    getTopUpList,
    getDisbursementApprovers,
    getDisbursementItemsByID,
    updateDisbursementItems,
    deleteDisbursementItems,
    validateDisbursementItems,
    getTopUpHistory,
    getTopUpBanks,
    submitDisbursement,
    uploadDisbursement,
    submitDisbursementFile,
    getCountAndAmount,
    getTopupInstructions,
    listDisbursements,
    getDisbursementMakers,
    getDisbursementByID,
    exportDisbursementBatchItems,
    rejectDisbursmentByID,
    approveDisbursementByID,
    resubmitDisbursementByID,
    getDisbursementAdmins,
    getTopUpInstructionsByID,
    getTransferFees
}